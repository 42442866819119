import React, { useState, useRef } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Form, Button, Row, Col, Spinner, Image } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { BsDot } from 'react-icons/bs';
import logo from '../../../assets/logo.png';
import CheckBox from '../../checkInput/checkBox';
import TextRow from './textRow';
import { validateCoupon, registerUser, validateUserNameEmail, addUserToMembership } from '../../../actions/register';

const translations = {
  eng: {
    title: 'Register for a',
    subTitle: 'New Account',
    login: 'Log in',
    loginDesc: 'if you wish to upgrade an existing subscription.',
    username: 'Username',
    password: 'Password',
    passAgain: 'Password Again',
    'first-name': 'First Name',
    'last-name': 'Last Name',
    phone: 'Teléfono',
    email: 'Email',
    couponCode: 'Coupon Code',
    dontHave: "Don't have a code?",
    codeDesc: (
      <>
        The Children's Century Classics online account is available with the purchase of the book set. If you have
        already purchased and received the set, your coupon code can be found either on the outside of the white box, or
        on a sheet of paper inside the box with instructions on how to set up your account. If you have not purchased
        the set already, please fill out THIS inquiry form to learn how you can purchase the set. If you have purchased
        the set but did not receive a coupon code, please send us a message by clicking{' '}
        <a href="mailto:support@childrenscenturyclassics.com">HERE.</a>
      </>
    ),
  },
  esp: {
    title: 'Regístrese para un',
    subTitle: 'Nueva cuenta',
    login: 'El Login',
    loginDesc: 'si desea actualizar una suscripción existente.',
    username: 'Nombre de usuario',
    password: 'Contraseña',
    passAgain: 'contraseña de nuevo',
    'first-name': 'Nombre',
    'last-name': 'Apellidos',
    phone: 'Teléfono',
    email: 'Correo electrónico',
    couponCode: 'Código promocional',
    dontHave: '¿No tienes un código?',
    codeDesc: (
      <>
        La cuenta en línea de Children's Century Classics está disponible con la compra del juego de libros. Si ya ha
        comprado y recibido el juego, su código de cupón se puede encontrar en el exterior de la caja blanca o en una
        hoja de papel dentro de la caja con instrucciones sobre cómo configurar su cuenta. Si aún no ha comprado el
        juego, complete ESTE formulario de consulta para saber cómo puede comprarlo. Si ha comprado el juego pero no ha
        recibido un código de cupón, envíenos un mensaje haciendo clic{' '}
        <a href="mailto:support@childrenscenturyclassics.com">AQUÍ.</a>
      </>
    ),
  },
};

const freeTrialText = {
  eng: {
    title: 'Free Trial - free - 30 Days',
    desc: 'The free trial gives you full access to all content for 30 days. After which you must upgrade to lifetime membership to continue accessing this content.',
    membership: 'MEMBERSHIP',
    amount: 'AMOUNT',
    freeTrial: 'Free trial',
    free: 'free',
    totalToday: 'TOTAL TODAY',
    register: 'Register',
  },
  esp: {
    title: 'Prueba gratuita - gratis - 30 días',
    desc: 'La prueba gratuita le brinda acceso completo a todo el contenido durante 30 días. Después de lo cual debe actualizar a una membresía de por vida para continuar accediendo a este contenido.',
    membership: 'AFILIACIÓN',
    amount: 'CANTIDAD',
    freeTrial: 'Prueba Gratis',
    free: 'gratis',
    totalToday: 'TOTAL HOY',
    register: 'Registrarse',
  },
};

const FreeTier = (props) => {
  const { language, superToken } = props;
  const [codeExpand, SetExpand] = useState(false);
  const [isValidCoupon, setIsValidCoupon] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isValidUserName, setIsValidUserName] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);

  const validator = yup.object({
    username: yup.string().required('Username is required'),
    email: yup.string().email('Invalid email').required('Email is required'),
    first_name: yup.string().required('First Name is required'),
    last_name: yup.string().required('Last Name is required'),
    password: yup.string().required('Password is required.'),
    re_password: yup
      .string()
      .required('Repeat Password is required.')
      .oneOf([yup.ref('password')], 'Passwords dont match'),
    coupon_code: yup.string().required('Coupon Code is required.'),
  });

  const handleValidateUserNameEmail = async (evt, type) => {
    const value = evt.target.value.trim();
    if (type === 'username' && value.length > 0) {
      const response = await validateUserNameEmail(type, value);
      if (!response.username) {
        setIsValidUserName(true);
      } else {
        setIsValidUserName(false);
      }
    }
    if (type === 'email' && value.length > 0) {
      const response = await validateUserNameEmail(type, value);
      if (!response.email) {
        setIsValidEmail(true);
      } else {
        setIsValidEmail(false);
      }
    }
  };

  const handleCouponVerification = async (evt) => {
    const coupon = evt.target.value;
    if (coupon.trim().length > 0) {
      const response = await validateCoupon(coupon);
      if (response.post_id) {
        setIsValidCoupon(true);
      } else {
        setIsValidCoupon(false);
      }
    }
  };

  const resetForm = (reset) => {
    reset({
      username: '',
      email: '',
      first_name: '',
      last_name: '',
      password: '',
      re_password: '',
      coupon_code: '',
    });
  };

  const handleRegisterUser = async (formField, reset) => {
    if (!isValidCoupon) return;
    setIsLoading(true);
    const response = await registerUser(formField, superToken);
    // Need to confirm the response
    if (response.id) {
      const result = await addUserToMembership(response.id, 2);
      if (result.status === 'success') {
        setHasError(false);
      } else {
        setHasError(true);
      }
    } else {
      setHasError(true);
    }
    setShowToast(true);
    setIsLoading(false);
    resetForm(reset);
  };

  const ModalMsg = (props) => {
    return (
      <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Image src={logo} />
        </Modal.Header>
        <Modal.Body>
          <p style={{ color: '#767A82' }}>
            {hasError
              ? 'An error occored while Registration. Please try again later.'
              : "Thank you for registering for the Children's Century Classics - Bible Stories For Children"}
          </p>
        </Modal.Body>
      </Modal>
    );
  };

  const formRef = useRef();

  return (
    <div className="h-100 bg-grey registration">
      <div className="bg-fillinfo">
        <div className="register-fillinfo-inner">
          <div className="dsp-flx flx-start flx-col flx-1">
            <p className="fnt-evolventa bold fnt-r-26 fnt-primary m-0">{translations[language].title}</p>
            <p className="fnt-evolventa bold fnt-r-26 fnt-black">{translations[language].subTitle}</p>
            <p className="fnt-poppins fnt-r-12 fnt-dark-d">
              {translations[language].login} {translations[language].loginDesc}
            </p>
          </div>
          <div>
            <Formik
              validationSchema={validator}
              initialValues={{
                username: '',
                email: '',
                first_name: '',
                last_name: '',
                password: '',
                re_password: '',
                coupon_code: '',
              }}
              onSubmit={(formField, { resetForm }) => {
                handleRegisterUser(formField, resetForm);
              }}
              innerRef={formRef}
            >
              {({ values, errors, handleChange, handleSubmit }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="12" controlId="validationFormik01">
                      <Form.Label>
                        {translations[language].username} <BsDot size={30} />
                      </Form.Label>
                      <Form.Control
                        className={!isValidUserName ? 'is-invalid' : ''}
                        type="text"
                        name="username"
                        value={values.username}
                        onChange={handleChange}
                        onBlur={(evt) => handleValidateUserNameEmail(evt, 'username')}
                        isInvalid={!!errors.username}
                      />
                      <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {!isValidUserName ? 'Username already exist.' : ''}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="12" controlId="validationFormik02">
                      <Form.Label>
                        {translations[language].email} <BsDot size={30} />
                      </Form.Label>
                      <Form.Control
                        className={!isValidEmail ? 'is-invalid' : ''}
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={(evt) => handleValidateUserNameEmail(evt, 'email')}
                        isInvalid={!!errors.email}
                      />
                      <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {!isValidEmail ? 'Email already exist.' : ''}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="12" controlId="validationFormik03">
                      <Form.Label>
                        {translations[language]['first-name']} <BsDot size={30} />
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="first_name"
                        value={values.first_name}
                        onChange={handleChange}
                        isInvalid={!!errors.first_name}
                      />
                      <Form.Control.Feedback type="invalid">{errors.first_name}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="12" controlId="validationFormik04">
                      <Form.Label>
                        {translations[language]['last-name']} <BsDot size={30} />
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="last_name"
                        value={values.last_name}
                        onChange={handleChange}
                        isInvalid={!!errors.last_name}
                      />
                      <Form.Control.Feedback type="invalid">{errors.last_name}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="12" controlId="validationFormik05">
                      <Form.Label>
                        {translations[language].password} <BsDot size={30} />
                      </Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        isInvalid={!!errors.password}
                      />
                      <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="12" controlId="validationFormik06">
                      <Form.Label>
                        {translations[language].passAgain} <BsDot size={30} />
                      </Form.Label>
                      <Form.Control
                        type="password"
                        name="re_password"
                        value={values.re_password}
                        onChange={handleChange}
                        isInvalid={!!errors.re_password}
                      />
                      <Form.Control.Feedback type="invalid">{errors.re_password}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="12" controlId="validationFormik07">
                      <Form.Label>
                        {translations[language].couponCode} <BsDot size={30} />
                      </Form.Label>
                      <Form.Control
                        className={!isValidCoupon ? 'is-invalid' : ''}
                        type="text"
                        name="coupon_code"
                        value={values.coupon_code}
                        onChange={handleChange}
                        onBlur={handleCouponVerification}
                        isInvalid={!!errors.coupon_code}
                      />
                      <Form.Control.Feedback type="invalid">{errors.coupon_code}</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {!isValidCoupon ? 'Coupon is invalid.' : ''}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <div className="dontHave">
                      <a
                        onClick={() => {
                          SetExpand(!codeExpand);
                        }}
                        className="fnt-poppins bold fnt-primary fnt-r-14 underline cursor-pointer"
                      >
                        {translations[language].dontHave}
                      </a>
                      <div className={`code-info-container ${codeExpand ? 'expand' : ''}`}>
                        <p className="fnt-evolventa fnt-12">{translations[language].codeDesc}</p>
                      </div>
                    </div>
                  </Row>
                  <div className="bg-grey p-4 col-md-8">
                    <div className="dsp-flx">
                      <CheckBox onCheck={() => {}} checkState={true} />
                      <p className="fnt-poppins fnt-weight-500 fnt-r-14 fnt-black ml-2 free-trial-text">
                        {freeTrialText[language].title}
                      </p>
                    </div>
                    <p className="fnt-poppins fnt-black fnt-r-12 free-trial-msg">{freeTrialText[language].desc}</p>
                  </div>
                  <div className="mt-4 mb-4 p-0 col-md-8">
                    <TextRow
                      borderBottom
                      leftText={freeTrialText[language].membership}
                      rightText={freeTrialText[language].amount}
                    />
                    <TextRow
                      borderBottom
                      leftText={freeTrialText[language].freeTrial}
                      rightText={freeTrialText[language].free}
                    />
                    <TextRow
                      borderBottom={false}
                      leftText={freeTrialText[language].totalToday}
                      rightText={freeTrialText[language].free}
                    />
                  </div>
                  <Button
                    type="submit"
                    className="fnt-r-14 no-border fnt-weight-600 bg-prime fnt-white fnt-poppins pt-2 pb-2 pl-4 pr-4"
                  >
                    {freeTrialText[language].register}
                    {isLoading ? (
                      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    ) : (
                      ''
                    )}
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <ModalMsg
        show={showToast}
        onHide={() => {
          setShowToast(false);
          setTimeout(() => {
            const signInBtn = document.getElementsByClassName('sign-in')[0];
            const event = new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              view: window,
            });
            signInBtn.dispatchEvent(event);
          }, 1000);
        }}
      />
    </div>
  );
};

export default FreeTier;
