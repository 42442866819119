import React from 'react';
import { Router } from '@reach/router';
import AuthStateProvider from '../components/authStateProvider';
import Header from '../components/header/header';
import SEO from '../components/SEO';
import FillInfo from '../components/registerComps/register';
import Footer from '../components/footer';

const RegisterPage = (props) => {
  return (
    <AuthStateProvider authRequired={false} className="h-100 bg-grey" {...props}>
      <SEO title="Register" />
      <Header hideMiddle={true} show={true} />
      <Router basepath="/register">
        <FillInfo path="/" />
      </Router>
      <Footer />
    </AuthStateProvider>
  );
};

export default RegisterPage;
