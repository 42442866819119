import React from 'react';

const TextRow = (props) => {
  const { leftText, rightText, borderBottom } = props;

  return (
    <div className={`txt-row-container ${borderBottom ? 'active' : ''}`}>
      <p className="fnt-poppins fnt-r-14 fnt-weight-600 fnt-dark-e mb-2 mt-2">{leftText}</p>
      <p className="fnt-poppins fnt-r-14 fnt-weight-600 fnt-dark-e mb-2 mt-2">{rightText}</p>
    </div>
  );
};
export default TextRow;
