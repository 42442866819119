import React from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import FreeTier from './registerTiers/freeTier.js';
import PaidTier from './registerTiers/paidTier.js';
import './style.scss';

const Register = (props) => {
  const { language, super_token } = useSelector((state) => state);
  const querySearch = new URLSearchParams(props.location.search);
  const level = querySearch.get('level');
  if (isEqual(level, '2')) {
    return <PaidTier language={language} superToken={super_token} />;
  }

  return <FreeTier language={language} superToken={super_token} />;
};

export default Register;
